#iiwsm .iiws__headerSelect {
  color: white;
  background: #0c67d0 !important;
  box-shadow: 0px 0px !important;
}

#iiwsm .euiCollapsibleNavGroup:not(:first-child) {
  border-top: 0px;
}

.iiws_langSelect .euiSuperSelectControl {
  font-size: 16px;
}

.iiws_langSelect path {
  fill: white !important;
}

.iiws_langSelect {
  margin-left: 6px;
  margin-right: 6px;
}

#iiws__sidebar {
  top: 49px;
  width: 200px;
}

.iiws_langSelect button {
  padding-left: 0px;
  padding-right: 20px;
}

.iiws_langSelect .euiFormControlLayoutIcons--right {
  right: 0px;
}

.iiws__headerDivide {
  height: 16px;
  width: 1px;
  background-color: white;
}

.iiws_content {
  position: fixed;
  width: 100%;
  height: 100%;
}

#iiws__userLogo path {
  fill: white !important;
}

.iiws__reactSvg {
  width: 20px;
  height: 20px;
}

.iiws__reactSvg div {
  width: 20px;
  height: 20px;
}

#iiws__content {
  width: 100%;
  height: 100%;
}

#iiws__sidebar a:link,
#iiws__sidebar a:visited,
#iiws__sidebar a:focus,
#iiws__sidebar a:hover {
  text-decoration: none;
}

#iiws__sidebar button:link,
#iiws__sidebar button:visited,
#iiws__sidebar button:focus,
#iiws__sidebar button:hover {
  text-decoration: none;
}

table,
tbody,
td,
tfoot,
th,
thead,
tr {
  margin: 0;
  padding: 0;
  border: none;
  vertical-align: middle;
}

.title_vertical_line {
  display: inline-block;
  vertical-align: middle;
  width: 5px;
  height: 27px;
  background: #007bd8;
  margin: 0 5px;
}

.euiHeaderSectionItem__button:hover {
  background-color: #0c67d0;
}

.euiHeaderSectionItem__button:focus {
  background-color: #0c67d0;
}

.iiws__header {
  background-color: #0c67d0 !important;
  color: white !important;
}

.euiBody--collapsibleNavIsDocked {
  padding-left: 200px !important;
}

.euiSuperSelect__listbox {
  width: 100% !important;
}

.euiSideNavItem--trunk > .euiSideNavItemButton {
  font-size: 20px;
  margin-left: 0px !important;
  padding-left: 16px;
  width: 100% !important;
}

.euiSideNavItemButton.euiSideNavItemButton-isSelected .euiSideNavItemButton__label {
  color: white;
}

.euiSideNavItemButton.euiSideNavItemButton-isSelected {
  background-color: #007bd8;
}

.euiSideNavItemButton__label {
  text-decoration: none !important;
  color: rgb(102, 102, 102);
}
